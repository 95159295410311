/* You can add global styles to this file, and also import other style files */
td a {
    font-size: 23px;
    cursor: pointer;
}
.delete_img {
    font-size: 23px;
    cursor: pointer;
}

.modal .modal-header .close{
    right: 24px!important;
    top: 24px!important;
}
.modal {
    overflow-y:auto!important;
    z-index: 1001 !important;
}

.modal-backdrop {z-index: 1000 !important;}
.pac-container {z-index: 1055 !important;}

.showing_line{
	margin-right:5px !important;
}