@media (min-width: 992px) {
    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

#map {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 70px;
}

.places-buttons .btn {
    margin-bottom: 30px
}

.space-70 {
    height: 70px;
    display: block;
}

.tim-row {
    margin-bottom: 20px;
}

.turbo-typo {
    padding-left: 25%;
    margin-bottom: 40px;
    position: relative;
}

.turbo-typo .turbo-note {
    bottom: 10px;
    color: #757575;
    display: block;
    font-weight: 500;
    font-size: 15px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
}

.tim-row {
    padding-top: 50px;
}

.tim-row h3 {
    margin-top: 0;
}

.fixed-plugin li > a, .fixed-plugin .badge {
    transition: all .34s;
    -webkit-transition: all .34s;
    -moz-transition: all .34s;
}

.fixed-plugin {
    position: fixed;
    top: 300px;
    right: 0;
    width: 64px;
    background: rgba(0,0,0,.3);
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
}

.fixed-plugin .fa-cog {
    color: #FFFFFF;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    width: auto;
}

.fixed-plugin .dropdown-menu {
    right: 80px;
    left: auto;
    width: 290px;
    border-radius: 10px;
    padding: 0 10px;
}

.fixed-plugin .dropdown-menu:after, .fixed-plugin .dropdown-menu:before {
    right: 10px;
    margin-left: auto;
    left: auto;
}

.fixed-plugin .fa-circle-thin {
    color: #FFFFFF;
}

.fixed-plugin .active .fa-circle-thin {
    color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a, .fixed-plugin .dropdown-menu > .active > a:hover, .fixed-plugin .dropdown-menu > .active > a:focus {
    color: #777777;
    text-align: center;
}

.fixed-plugin img {
    border-radius: 0;
    width: 100%;
    height: 100px;
    margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover, .fixed-plugin .dropdown-menu li > a:focus {
    box-shadow: none;
}

.fixed-plugin .badge {
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
}

.fixed-plugin .badge.active, .fixed-plugin .badge:hover {
    border-color: #00bbff;
}

.fixed-plugin .badge-blue {
    background-color: #2196F3;
}

.fixed-plugin .badge-green {
    background-color: #4CAF50;
}

.fixed-plugin .badge-yellow {
    background-color: #FFC107;
}

.fixed-plugin .badge-default {
    background-color: #607D8B;
}

.fixed-plugin .badge-red {
    background-color: #F44336;
}

.fixed-plugin .badge-black {
    background-color: #000;
}

.fixed-plugin .badge-white {
    background-color: rgba(200, 200, 200, 0.2);
}

.fixed-plugin h5 {
    font-size: 14px;
    margin: 10px;
}

.fixed-plugin .dropdown-menu li {
    display: block;
    padding: 5px 2px;
    width: 25%;
    float: left;
}

.fixed-plugin li.adjustments-line, .fixed-plugin li.header-title, .fixed-plugin li.button-container {
    width: 100%;
    height: 50px;
    min-height: inherit;
}

.fixed-plugin li.button-container {
    height: auto;
}

.fixed-plugin li.button-container div {
    margin-bottom: 5px;
}

.fixed-plugin #sharrreTitle {
    text-align: center;
    padding: 10px 0;
    height: 50px;
}

.fixed-plugin li.header-title {
    height: 30px;
    line-height: 25px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
    float: left;
    display: inline-block;
    margin-bottom: 0;
    font-size: 1em;
    color: #3C4858;
}

.fixed-plugin .adjustments-line a .badge-colors {
    position: relative;
    top: -2px;
}

.fixed-plugin .adjustments-line .togglebutton {
    float: right;
}

.fixed-plugin .adjustments-line .togglebutton .toggle {
    margin-right: 0;
}

.fixed-plugin .dropdown-menu > li.adjustments-line > a {
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
    background-color: #FFF;
    border: 3px solid #FFF;
    padding-left: 0;
    padding-right: 0;
    opacity: 1;
    cursor: pointer;
    max-height: 100px;
    overflow: hidden;
    padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.switch-trigger:hover, .fixed-plugin .dropdown-menu > li > a.switch-trigger:focus {
    background-color: transparent;
}

.fixed-plugin .dropdown-menu > li:hover > a.img-holder, .fixed-plugin .dropdown-menu > li:focus > a.img-holder {
    border-color: rgba(0, 187, 255, 0.53);
    ; }

.fixed-plugin .dropdown-menu > .active > a.img-holder, .fixed-plugin .dropdown-menu > .active > a.img-holder {
    border-color: #00bbff;
    background-color: #FFFFFF;
}

.fixed-plugin .dropdown-menu > li > a img {
    margin-top: auto;
}

.fixed-plugin .btn-social {
    width: 50%;
    display: block;
    width: 48%;
    float: left;
    font-weight: 600;
}

.fixed-plugin .btn-social i {
    margin-right: 5px;
}

.fixed-plugin .btn-social:first-child {
    margin-right: 2%;
}

.fixed-plugin .dropdown .dropdown-menu {
    -webkit-transform: translateY(-15%);
    -moz-transform: translateY(-15%);
    -o-transform: translateY(-15%);
    -ms-transform: translateY(-15%);
    transform: translateY(-15%);
    top: 27px;
    opacity: 0;
    transform-origin: 0 0;
}

.fixed-plugin .dropdown.open .dropdown-menu {
    opacity: 1;
    -webkit-transform: translateY(-13%);
    -moz-transform: translateY(-13%);
    -o-transform: translateY(-13%);
    -ms-transform: translateY(-13%);
    transform: translateY(-13%);
    transform-origin: 0 0;
}

.fixed-plugin .dropdown-menu:before, .fixed-plugin .dropdown-menu:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 78px;
    width: 16px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown-menu:before {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid rgba(0,0,0,0.2);
    border-top: 16px solid rgba(0,0,0,0);
    right: -17px;
}

.fixed-plugin .dropdown-menu:after {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid #FFFFFF;
    border-top: 16px solid rgba(0,0,0,0);
    right: -16px;
}

.wrapper-full-page ~ .fixed-plugin .dropdown.open .dropdown-menu {
    -webkit-transform: translateY(-17%);
    -moz-transform: translateY(-17%);
    -o-transform: translateY(-17%);
    -ms-transform: translateY(-17%);
    transform: translateY(-17%);
}

.wrapper-full-page ~ .fixed-plugin .dropdown .dropdown-menu {
    -webkit-transform: translateY(-19%);
    -moz-transform: translateY(-19%);
    -o-transform: translateY(-19%);
    -ms-transform: translateY(-19%);
    transform: translateY(-19%);
}

@media (min-width: 992px) {
    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

@media (max-width: 991px) {
    .wrapper-full-page ~ .fixed-plugin .dropdown-menu:after, .wrapper-full-page ~ .fixed-plugin .dropdown-menu:before {
        top: 99px;
    }

    .wrapper-full-page ~ .fixed-plugin .adjustments-line a .badge-colors {
        top: 3px;
    }

    .wrapper-full-page ~ .fixed-plugin .adjustments-line + .header-title {
        margin-top: 15px;
    }

    .fixed-plugin .dropdown-menu {
        right: 81px;
        width: 250px;
    }

    .fixed-plugin li.button-container {
        height: auto;
    }

    .fixed-plugin .adjustments-line .switch {
        float: right;
        margin: 0 0px;
    }

    .fixed-plugin li.header-title {
        height: 40px;
    }

    .fixed-plugin .dropdown .dropdown-menu {
        top: 10px;
    }

    .fixed-plugin .dropdown-menu:after, .fixed-plugin .dropdown-menu:before {
        top: 96px;
    }

    .fixed-plugin .dropdown-menu > li > a.img-holder {
        max-height: 75px;
    }

    .fixed-plugin {
        top: 80px;
    }
}

/*-------------------------------------
    Icons
--------------------------------------*/
.icons-card {
}

.icons-card ul {
    padding-left: 0;
    padding-bottom: 1px;
    margin-bottom: 20px;
    list-style: none;
    overflow: hidden;
}

.icons-card ul li {
    float: left;
    width: 25%;
    height: 100px;
    padding: 10px;
    margin: 0 -1px -1px 0;
    line-height: 1.4;
    text-align: center;
    border: 1px solid #ddd;
}

.icons-card ul li:hover {
    background-color: rgba(86,61,124,.1);
}

@media (min-width: 768px) {
    .icons-card ul li {
        width: 10%;
    }
}

.icons-card .card-content .zmdi {
    font-size: 30px;
    vertical-align: middle;
    color: #333;
}

.icons-card .card-content ul li span {
    font-size: 13px;
    margin-top: 10px;
    color: #5e5e5e;
    display: block;
    text-align: center;
    word-wrap: break-word;
}

/*-------------------------------------
    Cards
--------------------------------------*/
#button-dropdown {
    margin-left: 50px;
}

#invoice-logo {
    width: 250px;
}

/*-------------------------------------
    ChartJS
--------------------------------------*/
.chart-js-container {
    max-width: 70%;
    padding-left: 15%;
}
